/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  position: relative;
}

.header-image {
  background-size: cover;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 15px;
}

.h-100 {
  height: 100%;
}

.full-width {
  width: 100%;
}
.page-container {
  padding: 14px;
}
.form-row {
  margin-top: 5px;
  margin-bottom: 5px;
}

.top-menu {
  position: absolute;
  width: 200px;
  top: 0px;
  left: 50%;
  margin-left: -100px;
  background:rgba(0,0,0, 0.1);
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.top-menu-button {
  background-color: transparent !important;
  border: 0px;
  box-shadow: none !important;
  color: rgba(0,0,0, 0.54) !important;
}
.top-right-menu {
  position: absolute;
  top: 5px;
  right: 45px;
}
.question-field {
  margin-top: 10px;
}
.add-child-button {
  margin-left: 15px !important;
}

.segment-container {
  border: 4px solid #cfc4e9;
}

.group-container {
  border: 4px solid #bde0fe;
}

.field-container {
  border: 4px solid #ffe9c2;
}

.options-container {
  border: 4px solid #FEC8D8;
  padding: 15px;
}

.verification-container {
  border: 4px solid #ed8b76;
  padding: 15px;
}

.package-container {
  border: 4px solid #85DE77;
}

.list-title {
  margin-bottom: 15px;
}

.response-container {
  padding: 15px; margin-bottom: 15px;
}

.save-button-container {
  text-align: right;
  position: fixed !important;
  top: 75px;
  right: 20px;
  z-index: 1000;
}

.save-button-container > div {
  margin-bottom: 15px;
}

.header-select {
  margin-left: 10px;
}

.container-padding {
  padding: 16px 16px;
  margin: 16px 0px;
}

.mat-panel-details {
  color: rgba(0,0,0,.54);
}

.mat-panel-main-title {
  width: 60%;
}

.mat-panel-main {
  max-width: 80%;
}

.error-text {
  color: red;
  font-size: 12px;
}

.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.d-inline-block {
  display: inline-block;
}
.response-view-row {
  margin: 10px 0px;
  border-bottom: 1px dashed lightgray;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
}

.response-view-separator {
  margin: 10px 25px;
  font-weight: bold;
  padding-top: 15px;
  font-size: 1.1rem;
}
.response-view-header-row {
  font-weight: bold;
  font-size: 1.1rem;
  padding-left: 25px;
  padding-right: 25px;
}
.ng-table {
  table-layout: fixed;
  width: 100%;
}
.ng-table td {
  width: 50%;
}

.ng-tab-group-level-0 .mat-tab-header {
  background-color: #304ffe;
}

.ng-tab-group-level-0 .mat-tab-header .mat-tab-label {
  color: #fff !important;
  opacity: 1 !important;
}

.ng-tab-group-level-1 .mat-tab-header {
  background-color: #e0e0e0 !important;
}

.ng-tab-group-level-1 .mat-tab-header .mat-tab-label {
  color: #304ffe !important;
  opacity: 1 !important;
}

.ng-tab-group-level-0 mat-ink-bar {
  background-color: #fff !important;
}

.ng-tab-group-level-1 mat-ink-bar {
  background-color: #304ffe !important;
}

.helpText {
  color: #303030;
}

.inline-search-box {
  display: inline-block;
  margin-right: 10px;
}
.component-search-container-left {
  text-align: left;
}
.d-flex {
  display: flex;
}

.kyc-form-header {
  font-size: 16px;
  padding: 0px 16px;
  font-weight: 500;
}

.mat-autocomplete-panel mat-option.mat-option {
  height: auto !important;
  min-height: 48px;
  border-bottom: 1px solid #303030;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mat-option-text.mat-option-text {
  white-space: normal;
  line-height: normal;
}

.verification-field {
  margin-top: 10px;
  width: 25%;
  display: inline-block;
  padding-right: 15px;
}

@import "~@ng-select/ng-select/themes/material.theme.css";
@import '~@angular/cdk/overlay-prebuilt.css';

.verification-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.verification-item {
  flex: 1;
}

// required for derived field autocomplete (angular-text-input-autocomplete)
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
  text-decoration: none;
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
  color: #777;
}

.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.open>.dropdown-menu {
  display: block;
}

.open>a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right>.dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

.table-row {
  cursor: pointer;
}

.table-row:hover {
  background-color: lightgrey;
}

.response-header {
  background-color: #3f51b5;
}
